<template>
  <div class="container">
    <div class="notify" v-if="status==='0'">审核中</div>
    <div class="notify" v-if="status==='1'">审核通过</div>
    <div class="notify" v-if="status==='2'" style="color: #FF3D00;background: rgba(255, 61, 0, 0.1);">审核未通过,{{msg}}</div>
    <van-form class="form" ref="form">
      <div class="part">
<!--        <div class="flex-box">-->
<!--          <span class="required-span"> * </span>-->
<!--          <van-field v-model="form.customerName" placeholder="请输入客户名称" :rules="[{ required: true, message: '请输入客户名称' }]" :readonly="status==='0' || status==='1'" />-->
<!--        </div>-->
<!--        <div class="flex-box">-->
<!--          <span class="required-span"> * </span>-->
<!--          <van-field v-model="form.payAddress" placeholder="请输入缴纳地点" :rules="[{ required: true, message: '请输入缴纳地点' }]"  :readonly="status==='0' || status==='1'" />-->
<!--        </div>-->
<!--        <div class="flex-box">-->
<!--          <span class="required-span"> * </span>-->
<!--          <van-field v-model="form.startTime" placeholder="请输入开始缴纳时间" :rules="[{ required: true, message: '请输入开始缴纳时间' }]" :readonly="status==='0' || status==='1'" />-->
<!--        </div>-->
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.personName" placeholder="请输入姓名" :rules="[{ required: true, message: '请输入姓名' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.idCard" placeholder="请输入身份证号" :rules="[{ required: true, message: '请输入身份证号' }]" :readonly="status==='0' || status==='1'"  />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.phone" placeholder="请输入手机号"  maxlength="11" :rules="[{ required: true, message: '请输入手机号' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <!-- <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.payAddress" placeholder="请输入个人邮箱" :rules="[{ required: true, message: '请输入个人邮箱' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.nation" placeholder="请输入民族" :rules="[{ required: true, message: '请输入民族' }]" :readonly="status==='0' || status==='1'" />
        </div> -->
        <!-- <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.education" placeholder="请输入学历" :rules="[{ required: true, message: '请输入学历' }]" :readonly="status==='0' || status==='1'" />
        </div> -->
        <!-- <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.school" placeholder="请输入毕业院校" :rules="[{ required: true, message: '请输入毕业院校' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.major" placeholder="请输入专业" :rules="[{ required: true, message: '请输入专业' }]" :readonly="status==='0' || status==='1'" />
        </div> -->
        <!-- <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.hukuAddress" placeholder="请输入户籍所在地" :rules="[{ required: true, message: '请输入户籍所在地' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.address" placeholder="请输入现居住地" :rules="[{ required: true, message: '请输入现居住地' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.startTime" placeholder="请输入首次参加工作时间" :rules="[{ required: true, message: '请输入首次参加工作时间' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.politics" placeholder="请输入政治面貌" :rules="[{ required: true, message: '请输入政治面貌' }]" :readonly="status==='0' || status==='1'" />
        </div> -->
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.job" placeholder="请输入紧急联系人" :rules="[{ required: true, message: '请输入紧急联系人' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.hukuStatus" placeholder="请输入紧急联系人电话" :rules="[{ required: true, message: '请输入紧急联系人电话' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.cardNumber" placeholder="请输入工资卡号" :rules="[{ required: true, message: '请输入工资卡号' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <!-- <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field  v-model="form.bankNumber" placeholder="请输入工资卡号" :rules="[{ required: true, message: '请输入工资卡号' }]" :readonly="status==='0' || status==='1'"  />
        </div> -->
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.openBank" placeholder="请输入开户行" :rules="[{ required: true, message: '请输入开户行' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <!-- <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.personFundAccount" placeholder="请输入个人公积金账号" :rules="[{ required: true, message: '请输入个人公积金账号' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.beforeCompanyName" placeholder="请输入上家单位全称" :rules="[{ required: true, message: '请输入上家单位全称' }]" :readonly="status==='0' || status==='1'" />
        </div>
        <div class="flex-box">
          <span class="required-span"> * </span>
          <van-field v-model="form.beforeCompanyFundAccount" placeholder="请输入上家单位缴纳公积金账号" :rules="[{ required: true, message: '请输入上家单位缴纳公积金账号' }]" :readonly="status==='0' || status==='1'"  />
        </div> -->
      </div>
    </van-form>
    <div class="list">
<!--      <div class="list-item">-->
<!--          <div class="title">1.劳动合同 <span class="required-span"> * </span></div>-->
<!--          <div class="contract">-->
<!--            <van-uploader v-model="contractList" multiple :before-read="beforeRead"-->
<!--            :after-read="(file,detail)=>getFileList(file,detail,'contractList')"-->
<!--            :before-delete="(file,detail)=>delFileList(file,detail,'contractList')" :readonly="status==='0' || status==='1'">-->
<!--              <van-image-->
<!--                width="30"-->
<!--                height="30"-->
<!--                :src="require('./../../assets/images/icon-contract.png')"-->
<!--                style="margin-top: 50px;"-->
<!--              />-->
<!--              <div class="uploader-text">点击上传合同</div>-->
<!--            </van-uploader>-->
<!--          </div>-->
<!--      </div>-->
      <div class="list-item">
          <div class="title">1.身份证 <span class="required-span"> * </span></div>
          <div class="card flex-between">
            <van-uploader v-model="frontIdentityCard" :max-count="1" :before-read="beforeRead"
            :after-read="(file,detail)=>getFileList(file,detail,'frontIdentityCard')"
            :before-delete="(file,detail)=>delFileList(file,detail,'frontIdentityCard')"  :readonly="status==='0' || status==='1'">
              <van-image
                width="30"
                height="30"
                :src="require('./../../assets/images/icon-card.png')"
                style="margin-top: 25px;"
              />
              <div class="uploader-text">点击上传身份证人像面</div>
            </van-uploader>
            <van-uploader v-model="reverseIdentityCard" :max-count="1" :before-read="beforeRead"
            :after-read="(file,detail)=>getFileList(file,detail,'reverseIdentityCard')"
            :before-delete="(file,detail)=>delFileList(file,detail,'reverseIdentityCard')"  :readonly="status==='0' || status==='1'">
              <van-image
                width="30"
                height="30"
                :src="require('./../../assets/images/icon-card.png')"
                style="margin-top: 25px;"
              />
              <div class="uploader-text">点击上传身份证国徽面</div>
            </van-uploader>
          </div>
      </div>
      <div class="list-item">
        <div class="title">2.工资卡 <span class="required-span"> * </span></div>
        <div class="card flex-between">
          <van-uploader v-model="salaryCard" :max-count="1" :before-read="beforeRead"
                        :after-read="(file,detail)=>getFileList(file,detail,'salaryCard')"
                        :before-delete="(file,detail)=>delFileList(file,detail,'salaryCard')"  :readonly="status==='0' || status==='1'">
            <van-image
              width="30"
              height="30"
              :src="require('./../../assets/images/icon-card.png')"
              style="margin-top: 25px;"
            />
            <div class="uploader-text">点击上传工资卡正面</div>
          </van-uploader>
          <van-uploader v-model="reverseSalaryCard" :max-count="1" :before-read="beforeRead"
                        :after-read="(file,detail)=>getFileList(file,detail,'reverseSalaryCard')"
                        :before-delete="(file,detail)=>delFileList(file,detail,'reverseSalaryCard')"  :readonly="status==='0' || status==='1'">
            <van-image
              width="30"
              height="30"
              :src="require('./../../assets/images/icon-card.png')"
              style="margin-top: 25px;"
            />
            <div class="uploader-text">点击上传工资卡反面</div>
          </van-uploader>
        </div>
      </div>
      <div class="list-item">
          <div class="title">3.离职证明 <span class="required-span"> * </span></div>
          <div class="contract">
            <van-uploader v-model="lastContractList" multiple  :before-read="beforeRead"
            :after-read="(file,detail)=>getFileList(file,detail,'lastContractList')"
            :before-delete="(file,detail)=>delFileList(file,detail,'lastContractList')"  :readonly="status==='0' || status==='1'">
              <van-image
                width="30"
                height="30"
                :src="require('./../../assets/images/icon-contract.png')"
                style="margin-top: 50px;"
              />
              <div class="uploader-text">点击上传离职证明</div>
            </van-uploader>
          </div>
      </div>
      <div class="list-item">
        <div class="title">4.学历证书 <span class="required-span"> * </span></div>
        <div class="contract">
          <van-uploader v-model="edu" :max-count="1"  :before-read="beforeRead"
                        :after-read="(file,detail)=>getFileList(file,detail,'edu')"
                        :before-delete="(file,detail)=>delFileList(file,detail,'edu')"  :readonly="status==='0' || status==='1'">
            <van-image
              width="30"
              height="30"
              :src="require('./../../assets/images/icon-xueli.png')"
              style="margin-top: 50px;"
            />
            <div class="uploader-text">点击上传学历证书</div>
          </van-uploader>
        </div>
      </div>
      <div class="list-item" style=" padding-bottom: 100px">
        <div class="title">5.学位证书</div>
        <div class="contract">
          <van-uploader v-model="xuewei" :max-count="1"   :before-read="beforeRead"
                        :after-read="(file,detail)=>getFileList(file,detail,'xuewei')"
                        :before-delete="(file,detail)=>delFileList(file,detail,'xuewei')"  :readonly="status==='0' || status==='1'">
            <van-image
              width="30"
              height="30"
              :src="require('./../../assets/images/icon-xuewei.png')"
              style="margin-top: 50px;"
            />
            <div class="uploader-text">点击上传学位证书</div>
          </van-uploader>
        </div>
      </div>

      <div class="footer" v-if="!status">
        <div class="submit-btn3" @click="addCache">暂存</div>
        <div class="submit-btn2" @click="submitForm">提交</div>
      </div>
      <div class="footer"  v-if="status==='2'">
        <div class="submit-btn" @click="submitForm">提交</div>
      </div>
    </div>

  </div>
</template>

<script>
import { addCache, getUserInfo, updateUserInfo, upload } from '@/api/common';
export default {
  name: 'Index',
  components: {},
  data () {
    return {
      msg:'',
      form:{
        customerName:'',
        payAddress:'',
        startTime:'',
        personName:'',
        idCard:'',
        phone:'',
        nation:'',
        job:'',
        education:'',
        school:'',
        major:'',
        hukuStatus:'',
        hukuAddress:'',
        address:'',
        politics:'',
        socialSecurityNumber:'',
        fundNumber:'',
        personFundAccount:'',
        beforeCompanyFundAccount:'',
        beforeCompanyName:'',
        thirdEmployee:'',
        cardNumber:'',
        openBank:'',
        bankNumber:'',
        remark:''
      },
      contractList:[],
      frontIdentityCard:[],
      reverseIdentityCard:[],
      salaryCard:[],
      reverseSalaryCard:[],
      fundNumber:'',
      lastContractList:[],
      edu:[],
      xuewei:[],
      status:null,
      show:false
      }

  },
  created () {
    this.getUserInfo();
  },
  mounted () {

  },
  methods: {
    getUserInfo(){
      getUserInfo().then((res)=>{
        if(res.code === 200){
          this.form.customerName = res.data.userInfo.customerName
          this.form.payAddress = res.data.userInfo.payAddress
          this.form.startTime = res.data.userInfo.startTime
          this.form.personName = res.data.userInfo.personName
          this.form.idCard = res.data.userInfo.idCard
          this.form.phone = res.data.userInfo.phone
          this.form.nation = res.data.userInfo.nation
          this.form.job = res.data.userInfo.job
          this.form.education = res.data.userInfo.education
          this.form.school = res.data.userInfo.school
          this.form.major = res.data.userInfo.major
          this.form.hukuStatus = res.data.userInfo.hukuStatus
          this.form.hukuAddress = res.data.userInfo.hukuAddress
          this.form.address = res.data.userInfo.address
          this.form.politics = res.data.userInfo.politics
          this.form.socialSecurityNumber = res.data.userInfo.socialSecurityNumber
          this.form.fundNumber = res.data.userInfo.fundNumber
          this.form.personFundAccount = res.data.userInfo.personFundAccount
          this.form.beforeCompanyFundAccount = res.data.userInfo.beforeCompanyFundAccount
          this.form.beforeCompanyName = res.data.userInfo.beforeCompanyName
          this.form.thirdEmployee = res.data.userInfo.thirdEmployee
          this.form.cardNumber = res.data.userInfo.cardNumber
          this.form.openBank = res.data.userInfo.openBank
          this.form.bankNumber = res.data.userInfo.bankNumber
          this.form.remark = res.data.userInfo.remark
          this.status = res.data.userInfo.status
          this.msg = res.data.userInfo.reason
          // let contractList = []
          // res.data.contractList.forEach(x=>{
          //   contractList.push({url:x.fileUrl})
          // })
          // this.contractList = contractList
          //身份证人像面
          if(res.data.frontIdentityCard){
            this.frontIdentityCard = [{url:res.data.frontIdentityCard}];
          }
          //身份证国徽面
          if(res.data.reverseIdentityCard){
            this.reverseIdentityCard = [{url:res.data.reverseIdentityCard}];
          }
          //工资卡正面
          if(res.data.salaryCard){
            this.salaryCard = [{url:res.data.salaryCard}];
          }
          //工资卡反面
          if(res.data.reverseSalaryCard){
            this.reverseSalaryCard = [{url:res.data.reverseSalaryCard}];
          }

          // this.fundNumber = res.data.fundNumber;
          //离职证明
          let lastContractList = [];
          res.data.lastContractList.forEach(x=>{
            lastContractList.push({url:x.fileUrl})
          })
          this.lastContractList = lastContractList
          //学历证书
          if(res.data.edu){
            this.edu = [{url:res.data.edu}];
          }
          //学位证
          if(res.data.xuewei){
            this.xuewei = [{url:res.data.xuewei}];
          }
        }
      })
    },
    beforeRead (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        return true
      }
      this.$toast('请上传图片')
      return false
    },
    getFileList(file, detail,str){
      var that = this
      var formData = new FormData()
      formData.append('file', file.file)
      upload(formData).then(response => {
        if (response.code === 200) {
          // if(str === 'contractList'){
          //   that.contractList[detail.index].url = response.url;
          // }else
          //身份证正面
          if(str === 'frontIdentityCard'){
            that.frontIdentityCard[detail.index].url = response.url;
          }else if(str === 'reverseIdentityCard'){
            //身份证反面
            that.reverseIdentityCard[detail.index].url = response.url;
          }else if(str === 'salaryCard'){
            //工资卡正面
            that.salaryCard[detail.index].url = response.url;
          }else if(str === 'reverseSalaryCard'){
            //工资卡反面
            that.reverseSalaryCard[detail.index].url = response.url;
          }else if(str === 'lastContractList'){
            //离职证明
            that.lastContractList[detail.index].url = response.url;
          }else if(str === 'edu'){
            //学历证书
            that.edu[detail.index].url = response.url;
          }else if(str === 'xuewei'){
            //学位证书
            that.xuewei[detail.index].url = response.url;
          }

        }
      }).catch((e) => {
      })
    },
    delFileList(file, detail,str){
      var that = this
      if(that.status ==='0' || that.status ==='1'){
        return false
      }
      // if(str === 'contractList'){
      //   that.contractList.splice(detail.index,1);
      // }else
      //身份证正面
      if(str === 'frontIdentityCard'){
        that.frontIdentityCard.splice(detail.index,1);
      }else if(str === 'reverseIdentityCard'){
        //身份证反面
        that.reverseIdentityCard.splice(detail.index,1);
      }else if(str === 'salaryCard'){
        //工资卡正面
        that.salaryCard.splice(detail.index,1);
      }else if(str === 'reverseSalaryCard'){
        //工资卡反面
        that.reverseSalaryCard.splice(detail.index,1);
      }else if(str === 'lastContractList'){
        //离职证明
        that.lastContractList.splice(detail.index,1);
      }else if(str === 'edu'){
        //学历证书
        that.edu.splice(detail.index,1);
      }else if(str === 'xuewei'){
        //学位证书
        that.xuewei.splice(detail.index,1);
      }
    },
    submitForm(){
      var that = this
      // if(!that.form.customerName){
      //   that.$toast({message: '请输入客户名称',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.payAddress){
      //   that.$toast({message: '请输入缴纳地点',icon: 'none',duration: 2000})
      //   return false;
      // }
      // if(!that.form.startTime){
      //   that.$toast({message: '请输入开始缴纳时间',icon: 'none',duration: 2000})
      //   return false;
      // };
      if(!that.form.personName){
        that.$toast({message: '请输入姓名',icon: 'none',duration: 2000})
        return false;
      };
      if(!that.form.idCard){
        that.$toast({message: '请输入身份证号',icon: 'none',duration: 2000})
        return false;
      }
      if(!that.form.phone){
        that.$toast({message: '请输入手机号',icon: 'none',duration: 2000})
        return false;
      };
      // if(!that.form.nation){
      //   that.$toast({message: '请输入民族',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.job){
      //   that.$toast({message: '请输入岗位',icon: 'none',duration: 2000})
      //   return false;
      // }
      // if(!that.form.education){
      //   that.$toast({message: '请输入学历',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.school){
      //   that.$toast({message: '请输入毕业院校',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.major){
      //   that.$toast({message: '请输入专业',icon: 'none',duration: 2000})
      //   return false;
      // }
      // if(!that.form.hukuStatus){
      //   that.$toast({message: '请输入户籍性质',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.hukuAddress){
      //   that.$toast({message: '请输入户籍所在地',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.address){
      //   that.$toast({message: '请输入现居住地',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.politics){
      //   that.$toast({message: '请输入政治面貌',icon: 'none',duration: 2000})
      //   return false;
      // };
      if(!that.form.job){
        that.$toast({message: '请输入紧急联系人',icon: 'none',duration: 2000})
        return false;
      };
      if(!that.form.hukuStatus){
        that.$toast({message: '请输入紧急联系人电话',icon: 'none',duration: 2000})
        return false;
      };
      // if(!that.form.socialSecurityNumber){
      //   that.$toast({message: '请输入社保基数',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.fundNumber){
      //   that.$toast({message: '请输入公积金缴纳基数',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.thirdEmployee){
      //   that.$toast({message: '是否三期员工',icon: 'none',duration: 2000})
      //   return false;
      // };
      if(!that.form.cardNumber){
        that.$toast({message: '请输入工资卡号',icon: 'none',duration: 2000})
        return false;
      };
      if(!that.form.openBank){
        that.$toast({message: '请输入开户行',icon: 'none',duration: 2000})
        return false;
      };
      // if(!that.form.bankNumber){
      //   that.$toast({message: '请输入银行行号',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.beforeCompanyName){
      //   that.$toast({message: '请输入上家单位全称',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.beforeCompanyFundAccount){
      //   that.$toast({message: '请输入上家单位缴纳公积金账号',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(!that.form.personFundAccount){
      //   that.$toast({message: '请输入个人公积金账号',icon: 'none',duration: 2000})
      //   return false;
      // };
      // if(that.contractList && that.contractList.length <1){
      //   that.$toast({message: '请上传劳动合同照片',icon: 'none',duration: 2000})
      //   return false;
      // }
      if(that.frontIdentityCard && that.frontIdentityCard.length <1){
        that.$toast({message: '请上传身份证人像面',icon: 'none',duration: 2000})
        return false;
      }
      if(that.reverseIdentityCard && that.reverseIdentityCard.length <1){
        that.$toast({message: '请上传身份证国徽面',icon: 'none',duration: 2000})
        return false;
      }
      if(that.salaryCard && that.salaryCard.length <1){
        that.$toast({message: '请上传工资卡正面照片',icon: 'none',duration: 2000})
        return false;
      }
      if(that.reverseSalaryCard && that.reverseSalaryCard.length <1){
        that.$toast({message: '请上传工资卡反面照片',icon: 'none',duration: 2000})
        return false;
      }
      // if(!that.fundNumber){
      //   that.$toast({message: '请输入12位公积金个人代码',icon: 'none',duration: 2000})
      //   return false;
      // }
      if(that.lastContractList && that.lastContractList.length <1){
        that.$toast({message: '请上传离职证明',icon: 'none',duration: 2000})
        return false;
      }
      if(that.edu && that.edu.length <1){
        that.$toast({message: '请上传学历证书',icon: 'none',duration: 2000})
        return false;
      }
      // let contractList = [];
      // that.contractList.forEach(x=>{
      //   contractList.push(x.url);
      // })
      let lastContractList = [];
      that.lastContractList.forEach(x=>{
        lastContractList.push(x.url);
      })


      let data = {
        userInfo:that.form,
        // contractList:contractList,
        frontIdentityCard:that.frontIdentityCard[0].url,
        reverseIdentityCard:that.reverseIdentityCard[0].url,
        salaryCard:that.salaryCard[0].url,
        reverseSalaryCard:that.reverseSalaryCard[0].url,
        // fundNumber:that.fundNumber,
        lastContractList:lastContractList,
        edu:that.edu[0].url,
      }
      if(!(that.xuewei && that.xuewei.length <1)){
        data.xuewei = that.xuewei[0].url;
      }
      // if(that.status){
        updateUserInfo(data).then((res)=>{
          if(res.code === 200){
            that.getUserInfo()
          }
        })
      // }else{
      //   addUserInfo(data).then((res)=>{
      //     if(res.code === 200){
      //       that.getUserInfo()
      //     }
      //   })
      // }
    },

    addCache(){
      var that = this
      let data = {
        userInfo:that.form
      }
      if(!(that.frontIdentityCard && that.frontIdentityCard.length <1)){
        data.frontIdentityCard = that.frontIdentityCard[0].url;
      }
      if(!(that.reverseIdentityCard && that.reverseIdentityCard.length <1)){
        data.reverseIdentityCard = that.reverseIdentityCard[0].url;
      }
      if(!(that.salaryCard && that.salaryCard.length <1)){
        data.salaryCard = that.salaryCard[0].url;
      }
      if(!(that.reverseSalaryCard && that.reverseSalaryCard.length <1)){
        data.reverseSalaryCard = that.reverseSalaryCard[0].url;
      }

      if(!(that.lastContractList && that.lastContractList.length <1)){
        let lastContractList = [];
        that.lastContractList.forEach(x=>{
          lastContractList.push(x.url);
        })
        data.lastContractList = lastContractList;
      }
      if(!(that.edu && that.edu.length <1)){
        data.edu = that.edu[0].url;
      }
      if(!(that.xuewei && that.xuewei.length <1)){
        data.xuewei = that.xuewei[0].url;
      }
      addCache(data).then((res)=>{
        if(res.code === 200){
          that.getUserInfo()
        }
      })



    }

  }
}
</script>
<style>
  .contract .van-uploader__preview-image{
    width:105px;
    height: 150px;
  }
  .contract .van-uploader__input-wrapper{
    width:105px;
    height: 150px;
    text-align: center;
    border: dashed 1px #BBBBBB;
    border-radius: 4px;
  }
  .card .van-uploader__preview-image{
    width:165px;
    height: 104px;
  }
  .card .van-uploader__input-wrapper{
    width:165px;
    height: 104px;
    text-align: center;
    border: dashed 1px #BBBBBB;
    border-radius: 4px;
  }
  .excel .van-uploader__preview-image{
    width:345px;
    height: 50px;
  }
  .excel .van-uploader__input-wrapper{
    width:345px;
    height: 50px;
    text-align: center;
    border: dashed 1px #BBBBBB;
    border-radius: 4px;
  }
  /* .contract .van-uploader__input-wrapper */
</style>
<style scoped>
  .container{
    background: #fff;
    padding-bottom: 20px;
  }
  .notify{
    width: 100%;
    line-height: 34px;
    text-align: center;
    background: rgba(5, 178, 94, 0.1);
    color: #05B25E;
    font-size: 12px;
    border-top: solid 1px #E8E8E8;
  }
  .form .part{
    padding: 20px 15px 10px;
    border-bottom: solid 10px #FAFAFA;
  }
  .part:not(:last-child){

  }
  .required-span{
    color: red;
    padding-right: 5px;
  }
  .unrequired-span{
    padding-right: 10px;
  }
  .form-part{
    color: #FF9100;
    font-size: 13px;
    line-height: 20px;
    padding: 30px 0 20px 0;
  }
  .form .flex-box{
    margin-bottom: 20px;
  }
.list{

}
.list-item{
  background: #fff;
  padding: 20px 15px;
  border-bottom: solid 10px #FAFAFA;
}
.list-item:last-child{
  padding-bottom: 100px!important;
}
.list-item .title{
  color: #101010;
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 600;
}
.uploader-text{
  color: #9E9E9E;
  font-size: 12px;
  padding-top: 5px;
}
.down-text{
  color: var(--themeColor);
  font-size: 13px;
}
.excel .uploader-text{
  padding-top: 17px;
  padding-left: 10px;
}
.upload-img{
  margin-top: 10px;
}
.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 100;
  padding: 4px 15px;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  display: flex;
  justify-content: space-between;
}
.submit-btn3{
  width: calc(50% - 5px);
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  background: #E6A23C;
}
.submit-btn2{
  width: calc(50% - 5px);
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  background: var(--themeColor);
}
.submit-btn{
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  background: var(--themeColor);
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 80%;
    /* height: 260px; */
    background-color: #fff;
    color: #101010;
    font-size: 14px;
    line-height: 24px;
    padding: 30px 15px;
    border-radius: 10px;
  }
</style>
